export default {
  year: 2022,
  contests: [
    {
      title: "2022 ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["20", "7", "DPS", "김동건, 이준석, 전민수"],
        ["27", "6", "Last Coin", "강효규, 박재형, 채성우"],
        ["48", "4", "JaePiLee", "이선호, 이재혁, 한상필"],
      ],
      award: [],
      links: [
        ["공식 사이트", "http://icpckorea.org/2022-seoul/regional"],
        ["스코어보드", "http://static.icpckorea.net/20221119/scoreboard/"],
      ],
      review: [
        ["김동건(dong_gas)", "https://dong-gas.tistory.com/82"],
        ["이민희(minigimbob, 스태프 후기)", "https://www.minigb.io/220"],
        [
          "임지환(raararaara, 스태프 후기)",
          "https://blog.naver.com/raararaara/222944043938",
        ],
      ],
    },
    {
      title: "2022 ICPC Asia Seoul Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["18", "7", "DPS", "김동건, 이준석, 전민수"],
        ["34", "6", "Last Coin", "강효규, 박재형, 채성우"],
        ["48", "6", "SpartaMequeClub", "김정모, 정회윤, 조성훈"],
        ["62", "5", "JaePiLee", "이선호, 이재혁, 한상필"],
        ["63", "5", "AMUMAL", "성주희, 손기령, 이민희"],
        ["100", "4", "BIROSO", "김용현, 김예진, 박민성"],
        ["111", "3", "golgolgol", "김민성, 박정빈, 이성진"],
        ["121", "3", "SirenOrder", "유호영, 이우진, 한성환"],
        ["151", "3", "Introverts", "도정민, 성수빈, 오상윤"],
        ["156", "3", "whynot", "안성훈, 윤성호, 이서일"],
        ["158", "3", "BronzeKing123", "강다혜, 오승철, 천성원"],
        ["174", "3", "HunMukGi", "강승묵, 하지훈, 한석기"],
        ["201", "2", "JySyletsKo", "고재현, 김서연, 임정연"],
        ["245", "2", "JunSung", "강준성, 김유이, 류지성"],
        ["246", "2", "ABEFGCD", "김태규, 윤진우, 정파란"],
      ],
      award: [
        "advanced",
        "advanced",
        "advanced",
        "advanced",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      links: [
        ["공식 사이트", "http://icpckorea.org/2022-seoul/regional"],
        [
          "스코어보드",
          "http://static.icpckorea.net/2022/scoreboard_preliminary/",
        ],
      ],
      review: [
        ["김동건(dong_gas)", "https://dong-gas.tistory.com/80"],
        ["이민희(minigimbob)", "https://www.minigb.io/217"],
      ],
    },
    {
      title: "Google Kick Start Round C 2022",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["739 / 🇰🇷28", "59", "S4NGP1L", "한상필"]],
      award: [""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/00000000008cb4d1",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 3 2022",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["506 / 🇰🇷29", "30", "shiftpsh", "박수현"]],
      award: ["advanced"],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/00000000008779b4",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 2 2022",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["605 / 🇰🇷50", "35", "shiftpsh", "박수현"],
        ["1661 / 🇰🇷109", "22", "Rebro", "박재형"],
      ],
      award: ["advanced", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/00000000008778ec",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1A 2022",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["187 / 🇰🇷15", "100", "shiftpsh", "박수현"]],
      award: ["advanced"],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/000000000087711b",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1B 2022",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["187 / 🇰🇷6", "85", "Rebro", "박재형"]],
      award: ["advanced"],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/000000000087711b",
        ],
      ],
      review: [],
    },
    {
      title: "Samsung Collegiate Programming Contest 2022 Finals",
      columns: ["#", "이름"],
      data: [["5", "조원빈"]],
      award: [""],
      links: [
        ["공식 사이트", "https://www.codeground.org/scpc/commons/honer/list"],
      ],
      review: [],
    },
    {
      title: "SUAPC 2022 Summer",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["1", "11", "우승하러 왔습니다", "강효규, 박재형, 채성우"],
        ["2", "10", "DSP", "김동건, 이준석, 전민수"],
        ["6", "8", "djs212222", "김서인, 김정모, 이재혁"],
        ["7", "7", "-----수상컷-----", "성주희, 조성훈, 정회윤"],
        ["10", "6", "종환지훈준석", "이지훈, 이준석, 임종환"],
      ],
      award: ["gold", "silver", "bronze", "bronze", "bronze"],
      links: [
        ["공식 사이트", "https://suapc.kr"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/840"],
      ],
      review: [
        ["김동건(dong_gas)", "https://dong-gas.tistory.com/77"],
        ["박재형(rebro, pjh6792)", "https://rebro.kr/200"],
        ["조성훈(shandy5833)", "https://shandy5833.tistory.com/24"],
      ],
    },
    {
      title: "2022 ICPC Sinchon Summer Algorithm Camp Contest - Advanced",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1", "3", "dong_gas", "김동건"],
        ["2", "3", "yunny_world", "정회윤"],
      ],
      award: ["gold", "silver"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/842"],
      ],
      review: [["정회윤(yunny_world)", "https://yunny-world.tistory.com/33"]],
    },
    {
      title: "2022 ICPC Sinchon Summer Algorithm Camp Contest - Novice",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1", "4", "jeongbin", "박정빈"],
        ["3", "4", "junlee1216", "이준석"],
      ],
      award: ["gold", "bronze"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/841"],
      ],
      review: [],
    },
    {
      title: "SUAPC 2022 Winter",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["1", "7", "5시간 집중하기 힘든 나이", "박재형, 오재석, 진상우"],
        ["2", "7", "서강베스트", "김동건, 이재혁, 조원빈"],
        ["4", "7", "No Seastar Can’t Win", "김정모, 김준우, 박준서"],
        ["5", "7", "ECM", "권지은, 김성현, 김창민"],
        ["6", "7", "BIROSO", "김예진, 김용현, 박민성"],
        ["9", "7", "so강은 so 강하다", "강효규, 김서인, 신동준"],
      ],
      award: ["gold", "silver", "bronze", "bronze", "bronze"],
      links: [
        ["공식 사이트", "https://suapc.kr"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/764"],
      ],
      review: [],
    },
    {
      title: "2022 ICPC Sinchon Winter Algorithm Camp Contest - Advanced",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["2", "5", "meque98", "김정모"],
        ["3", "5", "p_jun", "박준서"],
      ],
      award: ["silver", "bronze"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/758"],
      ],
      review: [],
    },
    {
      title: "2022 ICPC Sinchon Winter Algorithm Camp Contest - Novice",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["1", "5", "imearth01", "이준하"]],
      award: ["gold"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/759"],
      ],
      review: [],
    },
  ],
};
